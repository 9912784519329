.section.intro {
  position: static;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  overflow: visible;
  width: 100%;
  height: 90vh;
  margin: auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -o-object-fit: fill;
  object-fit: fill;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  height: 100%;
  padding-bottom: 96px;
  padding-top: 96px;
  background-color: #171b2a;
  font-family: Inter,sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.container.headerpadding.mobileheader {
  padding-top: 120px;
}

.container.headerpadding {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  padding-top: 140px;
  padding-bottom: 140px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  object-fit: cover;
}

.container {
  display: block;
  overflow: visible;
  width: 100%;
  height: 100%;
  max-width: 1296px;
  min-height: 100%;
  margin-right: auto;
  margin-left: auto;
  -webkit-perspective: 500px;
  perspective: 500px;
  -o-object-fit: contain;
  object-fit: contain;
  padding-left: 0px;
  padding-right: 0px;
}

.content-width-extra-large.home-container {
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.content-width-extra-large {
  width: 100%;
  max-width: 964px;
  margin-right: auto;
  margin-left: auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  text-align: center;
}

.display-heading-2.display-inline-block {
  text-align: left;
}

.display-heading-1, .display-heading-2, .display-heading-3 {
    font-size: 54px;
    line-height: 54px;
}

.display-inline-block {
  display: inline-block;
}

.display-heading-2 {
  margin-bottom: 36px;
  font-family: Inter,sans-serif;
  color: #fff;
  font-size: 92px;
  line-height: 88px;
  font-weight: 700;
  text-align: center;
  letter-spacing: -.02em;
}

h3 {
  margin-top: 0;
  margin-bottom: 24px;
  font-family: Inter,sans-serif;
  color: #fff;
  font-size: 28px;
  line-height: 36px;
  font-weight: 600;
  letter-spacing: -.01em;
}

@media screen and (max-width: 767px){
  .section {
      margin-top: 72px;
      margin-bottom: 72px;
  }
}

@media screen and (max-width: 991px) {
  .section {
    margin-top: 96px;
    margin-bottom: 96px;
  }
}

.section {
  margin-top: 100px;
  margin-bottom: 100px;
}

  .container.grid-container {
    display: block;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    -o-object-fit: cover;
    object-fit: cover;
}

.fullscreen-section-content {
  position: static;
  display: block;
  padding-top: 0;
  padding-bottom: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.panel.bg-gradient {
  background-image: linear-gradient(90deg, #ff715b, #5132c0);
}

.panel {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 12px;
  background-color: hsla(0,0%,100%,.05);
  direction: ltr;
}

.panel-body.panel-body-large.align-content-center.rounded.purposepadding {
  padding-top: 80px;
  padding-bottom: 80px;
}

.panel-body.panel-body-large {
  padding: 36px;
}

.panel-body.align-content-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  padding-top: 0;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 0;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.panel-body.align-content-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.panel-body.panel-body-large {
  padding: 48px;
}

.rounded {
  border-radius: 6px;
}

.align-content-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.rounded {
  border-radius: 12px;
}
.panel-body-large {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 48px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.panel-body {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 36px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.heading-3 {
  text-align: left;
}

h1 {
  font-size: 32px;
  line-height: 40px;
}

h1 {
  margin: 0 0 24px;
  font-family: Inter,sans-serif;
  color: #fff;
  font-size: 40px;
  line-height: 44px;
  font-weight: 600;
  letter-spacing: -.02em;
}

h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.text-block-2 {
    font-family: Inter,sans-serif;
    color: #fff;
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    text-align: left;
    letter-spacing: normal;
    text-decoration: none;
    text-transform: none;
    white-space: normal;
    -o-object-fit: fill;
    object-fit: fill;
}

.navbar-wrapper.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
}

.navbar-wrapper {
  position: relative;
  z-index: 5;
  background-color: #12141d;
}